<template>
	<div>
		<h3
			class="font-light text-color-text"
			:class="size === 'small' ? 'mb-1 text-sm lg:text-inherit' : 'text-2xl mb-4'"
		>
			Tätigkeitsbereiche
		</h3>
		<div class="flex flex-wrap">
			<div
				v-for="(field, index) in visibleFields(showAllFields, company)"
				:key="index"
				:class="size === 'small' ? 'text-xs' : 'text-sm'"
				class="font-light tracking-wide bg-color-grey-lightest px-3 py-1 rounded-full mb-2 mr-2"
			>
				<span
					v-if="
						company && company.fields && company.fields.length > 5 && index === 4 && !showAllFields
					"
					class="cursor-pointer"
					@click="$emit('show-all-fields', true)"
				>
					+{{ company.fields.length - 4 }} Kategorien
				</span>
				<span v-else @click="$emit('field-clicked', field.name)">{{ field.name }}</span>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { IAPICompany } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { visibleFields } from '../../helpers/company-getter';

export default defineComponent({
	name: 'Fields',
	emits: ['show-all-fields', 'field-clicked'],
	data() {
		return {
			visibleFields
		};
	},
	props: {
		showAllFields: { type: Boolean, default: false },
		company: { type: Object as PropType<IAPICompany>, required: true },
		size: {
			type: String,
			default: 'normal',
			validator: (value: string) => ['small', 'normal'].includes(value)
		}
	}
});
</script>
