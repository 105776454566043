<template>
	<div
		class="flex flex-wrap items-center justify-center"
		:class="size === 'small' ? 'flex-row space-x-3' : 'flex-col lg:flex-row space-x-0 lg:space-x-3'"
	>
		<div
			v-if="locationCountForCompany"
			class="text-center font-light flex items-center"
			:class="size === 'small' ? 'lg:text-md mb-0' : 'lg:text-lg mb-2 lg:mb-0'"
		>
			<HokIcon :size="4" name="icon:pin-2" class="mr-1" />
			{{ getLocationText(company) }}
		</div>
		<div
			v-if="locationCountForCompany - 1 > 0"
			class="font-light bg-color-grey-lightest px-2 py-1 lg:px-3 rounded-full cursor-pointer"
			:style="{ 'font-size': `${size === 'small' ? '0.5rem' : '0.75rem'}` }"
			@click="e => $emit('to-anchor', e, 'locations')"
		>
			+ {{ locationCountForCompany - 1
			}}{{ locationCountForCompany - 1 === 1 ? ' Standort' : ' Standorte' }}
		</div>
	</div>
</template>
<script lang="ts">
import { IAPICompany } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { locationCount, getLocationText } from '../../helpers/company-getter';

export default defineComponent({
	name: 'Location',
	emits: ['to-anchor'],
	data() {
		return {
			locationCount,
			getLocationText
		};
	},
	computed: {
		locationCountForCompany(): number {
			return this.locationCount(this.company) || 0;
		}
	},
	props: {
		company: { type: Object as PropType<IAPICompany>, required: true },
		size: {
			type: String,
			default: 'normal',
			validator: (value: string) => ['small', 'normal'].includes(value)
		}
	}
});
</script>
